import React from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import AppWrap from "../../wrapper/AppWrap";
import "./Header.scss";
import { TfiHandPointRight } from "react-icons/tfi";
import { FaFilePdf } from "react-icons/fa";
import pdfFile from "../../assets/Boris_Tasevski_CV.pdf";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

function Header() {
  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>
              <TfiHandPointRight />
            </span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello, my name is</p>
              <h1 className="head-text">Boris</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text">Front-end Developer</p>
            <p className="p-text">Open to work</p>
            <a href={pdfFile} download="Boris_Tasevski_CV.pdf">
              Download CV <FaFilePdf />{" "}
            </a>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.git, images.react, images.sass].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="profile_bg" />
          </div>
        ))}
      </motion.div>
    </div>
  );
}

export default AppWrap(Header, "home");
